import { Controller } from "@hotwired/stimulus"
import "intl-tel-input/build/css/intlTelInput.css"

export default class extends Controller {
  static values = { countryCode: String }
  static targets = ["input"]

  connect() {
    import("intl-tel-input").then(({default: intlTelInput}) => {
      this.telInput = intlTelInput(this.inputTarget, {
        allowDropdown: true,
        autoHideDialCode: true,
        formatOnDisplay: true,
        initialCountry: this.countryCodeValue,
        nationalMode: false,
        onlyCountries: ["us","gb","in","ca","de","au","ph","ng","za","es","fr","pt","nl","mx","br","ie","it","tr","id","ke","sg","my","jp","pk","se","hk","ru","ar","hu","ch","pl","rs","co","vn","lr","cl","cn","at","th","il"],
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/20.0.5/js/utils.min.js"
      })
    })
  }

  formatNumber() {
    if (typeof intlTelInputUtils !== "undefined") { // utils are lazy loaded, so must check
      let currentText = this.telInput.getNumber(intlTelInputUtils.numberFormat.E164);
      if (typeof currentText === "string") { // sometimes the currentText is an object :)
        this.telInput.setNumber(currentText); // will autoformat because of formatOnDisplay=true
      }
    }
  }
}
