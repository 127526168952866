import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    data: Array
  }

  connect() {
    import('chart.js').then( ({Chart, registerables}) => {

      Chart.register(...registerables)
      var myChart = new Chart(this.element, {
        type: 'bar',
        data: {
          labels: ['< $60K', '$60K - $69K', '$70K - $79K', '$80K - $89K', '$90K - $99K', '$100K - $109K', '$110K +'],
          datasets: [{
            // data: [12, 19, 3, 5, 2, 3],
            data: this.dataValue,
            backgroundColor: "#254F064D"
          }]
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: "Graduate Salary"
              }
            },
            y: {
              beginAtZero: true,
              ticks:{
                callback: function (value, index, values) {
                  return `${value}%`;
                }
              }
            }
          },
          plugins:{
            legend:{
              display: false
            }
          },
          responsive: true,
          maintainAspectRatio: true
        }
      })

    })
  }
}
