import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.prevFocus = document.activeElement
    this.prevFocus.blur()
    this.focusable = this.element.querySelectorAll("button, [href], input:not(input[type='hidden']), textarea, [tabindex='0']")
    this.dir = null
  }

  handleFocusIn(event) {
    if (this.element.contains(event.target)) {
      return
    }

    if (this.dir == "backward") {
      this.focusable.item(this.focusable.length-1).focus()
    } else {
      this.focusable.item(0).focus()
    }
  }

  handleTabDown(event) {
    if (event.keyCode !== 9) {
      return
    }

    this.dir = event.shiftKey ? "backward" : "forward"
  }

  disconnect() {
    this.prevFocus.focus()
  }
}
