import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (event) => {
      Rails.fire(this.element.form, 'submit');
    })
  }
}
