import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    url: String,
    page: Number,
    enabled: Boolean,
  };

  initialize() {
    this.scroll = this.scroll.bind(this);
    this.pageValue = this.pageValue || 2;
  }

  connect() {
    document.addEventListener("scroll", this.scroll);
  }

  scroll() {
    if (this.scrollReachedEnd && this.enabledValue) {
      this._fetchNewPage()
    }
  }

  async _fetchNewPage() {
    const url = new URL(this.urlValue);
    url.searchParams.set('page', this.pageValue)
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'script'
    })

    this.pageValue +=1;
  }

  get scrollReachedEnd() {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
    return distanceFromBottom < 20; // adjust the number 20 yourself
  }
}
