import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.items = this.element.querySelectorAll('a');
    this.items.forEach(tocElement => {
      if (document.location.hash  == "" && tocElement.getAttribute('href') == "#overview") {
        tocElement.classList.add('selected')
      }
      if (document.location.hash  == tocElement.getAttribute('href')) {
        tocElement.classList.add('selected')
      }
      tocElement.addEventListener('click', (e) => {
        this.removeSelectedClass()
        e.target.classList.add('selected')
      })
    })
  }

  removeSelectedClass() {
    this.items.forEach(tocElement => {
      tocElement.classList.remove('selected')
    })
  }
}
