import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    params: Object
  }

  customEventHandler() {
    if (typeof dataLayer != "undefined") {
      dataLayer.push(this.paramsValue)
    }
  }
}
