import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.tocLinks = this.element.querySelectorAll('.sidebar-toc a')
    this.foundIndex = 0;
  }

  updateActiveToc(e) {
    var yPos;
    var visibleElements = [];
    var foundVisible = false;
    this.tocLinks.forEach((el, i) => {
      var headerEl = document.querySelector(el.getAttribute('href'))
      yPos = headerEl.getBoundingClientRect().top
      if (yPos > -53 && yPos < (window.innerHeight / 3) && !foundVisible) {
        foundVisible = true
        this.foundIndex = i
        document.querySelector('.sidebar-toc a.selected').classList.remove('selected')
        el.classList.add('selected')
      }
    })
    // When the element scrolls off the bottom of the page, select the previous element as the user scrolls up
    if (!foundVisible && this.foundIndex > 0 && this.foundIndex < this.tocLinks.length) {
      var el = this.tocLinks[this.foundIndex];
      var headerEl = document.querySelector(el.getAttribute('href'))
      yPos = headerEl.getBoundingClientRect().top
      if (yPos > window.innerHeight) {
        document.querySelector('.sidebar-toc a.selected').classList.remove('selected')
        this.tocLinks[this.foundIndex + -1].classList.add('selected')
      }
    }
  }
}
