import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "target"];

  toggleDisabledState() {
    if (this.sourceTarget.checked) {
      Array.prototype.forEach.call(this.targetTargets, function(el, i) {
        el.classList.add('disabled')
        el.disabled = true
        el.checked = false
      })
    } else {
      Array.prototype.forEach.call(this.targetTargets, function(el, i) {
        el.classList.remove('disabled')
        el.disabled = false
      })
    }
  }
}
