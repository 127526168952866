import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dropdown",
    "input"
  ]

  connect() {
    this.updateCount()
  }

  click(event) {
    if (this.dropdownTarget.contains(event.target)) {
    } else if (this.inputTarget === event.target) {
      this.dropdownTarget.classList.toggle('hidden')
    } else {
      this.close()
    }
  }

  open(event) {
    this.dropdownTarget.classList.remove('hidden')
  }

  close() {
    this.dropdownTarget.classList.add('hidden')
  }

  checkboxStateChange(e) {
    if (e.target.checked) {
      e.target.nextSibling.classList.add('bg-powderBlue')
    } else {
      e.target.nextSibling.classList.remove('bg-powderBlue')
    }
    this.updateCount()
  }

  updateCount() {
    let numberSelected = this.dropdownTarget.querySelectorAll('input:checked').length
    if (numberSelected === 0) {
      this.inputTarget.placeholder = 'Please Select'
    } else {
      this.inputTarget.placeholder = `${numberSelected} Selected`
    }
  }

  keydown(e) {
    // 9 = Tab
    // 27 = Escape
    if (e.keyCode == 9 || e.keyCode == 27) {
      this.close()
    }
  }
}
