import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.autocomplete = this.element.closest("[data-controller=autocomplete]")

    this.autocomplete.addEventListener('autocomplete.change', (event) => {
      this.element.form.submit()
    })
  }
}
