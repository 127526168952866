import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    url: String,
    param: String
  }

  select(event) {
    this.makeRequest(`${this.urlValue}?${this.paramValue}=${event.currentTarget.value}`)
  }

  makeRequest(url) {
    Rails.ajax({
      type: "get",
      url: url,
      dataType: 'script'
    })
  }
}
