import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "display"]
  static values = {
    maxlength: Number
  }

  connect() {
    this.updateCount()
  }

  updateCount() {
    this.displayTarget.textContent = `${this.maxlengthValue - this.inputTarget.value.length} Characters Available`
  }
}
