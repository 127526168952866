import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "close"];

  disconnect() {
    this.close();
  }

  open() {
    this.element.classList.remove("hidden");
  }

  close() {
    this.element.remove();
  }

  keydown(event) {
    if (event.key.toLowerCase() == "escape") {
      this.close();
    }
  }

  // When an element like <template data-modal-target="trigger"></template> is added inside the controller scope, the modal is opened
  triggerTargetConnected(_e) {
    this.open();
  }

  closeTargetConnected(_e) {
    this.close();
  }
}
