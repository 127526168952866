import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form" ];

  updateAndSubmit() {
    const fieldToUpdateSelector = event.target.dataset['update']
    if (!fieldToUpdateSelector) { return }

    const fieldToUpdate = document.querySelector(fieldToUpdateSelector)
    if (!fieldToUpdate) { return }

    fieldToUpdate.value = event.target.value
    fieldToUpdate.closest('form').submit()
  }
}
