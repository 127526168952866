import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "school", "course", "campus"];
  static values = {
    excludeOther: Boolean
  }

  handleSelectChange(e) {
    if (isNaN(e.target.value)) {
      if (e.target == this.schoolTarget) {
        this.clearSelect(this.campusTarget, "campuses");
      }
      this.clearSelect(this.courseTarget, "courses");
    } else {
      this.clearSelect(this.courseTarget, "courses");
      if (e.target == this.schoolTarget && this.validId(this.schoolTarget.value)) {
        this.populateSelect(this.schoolTarget.value, this.campusTarget, "campuses");
      }
      if (e.target == this.campusTarget) {
        if (this.validId(this.campusTarget.value)) {
          this.populateSelect(this.campusTarget.value, this.courseTarget, "courses");
        } else if (this.campusTarget.value == -1) { // Other selected
          this.addOtherToSelectBox(this.courseTarget)
        }
      }
    }
  }

  populateSelect(sourceId, targetSelect, targetRoutePart) {
    const targetRoutePartToLabelMap = {
      'campuses': 'Campus',
      'courses': 'Course'
    }

    fetch(`/${this.data.get('sourceRoutePart')}/${sourceId}/${targetRoutePart}`, {
      credentials: 'same-origin',
      headers: { "X-Requested-With": "XMLHttpRequest" }
    })
      .then(response => response.json())
      .then(data => {
        const selectBox = targetSelect;
        selectBox.options[0].selected = true;
        selectBox.dispatchEvent(new Event('change'));
        selectBox.innerHTML = '';
        var opt = document.createElement('option');
        opt.innerHTML = `Select ${targetRoutePartToLabelMap[targetRoutePart]}`;
        selectBox.appendChild(opt);
        data.forEach(item => {
          const opt = document.createElement('option');
          opt.value = item.id;
          opt.innerHTML = item[this.data.get('displayAttribute')];
          selectBox.appendChild(opt);
        });
        if (!this.excludeOtherValue) {
          this.addOtherToSelectBox(selectBox)
        }
    })
  }

  addOtherToSelectBox(selectBox) {
    var opt = document.createElement('option');
    opt.value = -1;
    opt.innerHTML = 'Other';
    selectBox.appendChild(opt);
  }

  clearSelect(targetSelect, targetRoutePart) {
    const targetRoutePartToLabelMap = {
      'campuses': 'Campus',
      'courses': 'Course'
    }

    const selectBox = targetSelect;
    selectBox.options[0].selected = true;
    selectBox.dispatchEvent(new Event('change'));
    selectBox.innerHTML = '';
    var opt = document.createElement('option');
    opt.innerHTML = `Select ${targetRoutePartToLabelMap[targetRoutePart]}`;
    selectBox.appendChild(opt);
  }

  validId(id) {
    return(id != "" && id != -1 && !isNaN(id))
  }
}
