import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    let id = 'facebook-jssdk';
    var js, fjs = document.getElementsByTagName('script')[0];
    if (document.getElementById(id)) { return; }
    js = document.createElement('script'); js.id = id; js.async=true;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }

  share(event) {
    event.preventDefault()

    FB.ui(
      {
        method: "share",
        href: this.urlValue
      },
      function (response) {
        if (response && !response.error_message) {
          // do nothing
        } else {
          console.log('Error while posting.');
        }
      }
    )
  }
}
