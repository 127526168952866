import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectContainer", "textFieldContainer"];
  static values = { toggle: String }

  handleSelectChange() {
    if (event.target.value == this.toggleValue) {
      this.selectContainerTarget.classList.replace('w-full', 'w-1/2')
      this.selectContainerTarget.classList.add('pr-3')
      this.textFieldContainerTarget.classList.remove('hidden')
    } else {
      this.selectContainerTarget.classList.replace('w-1/2', 'w-full')
      this.selectContainerTarget.classList.remove('pr-3')
      this.textFieldContainerTarget.classList.add('hidden')
    }
  }
}
