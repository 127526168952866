import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["reviews", "filter", "search"]
  static values = {
    filterBy: String,
    url: String
  }

  filter(event) {
    this.filterByValue = event.currentTarget.value
    this.urlValue = `${window.location.pathname}?${this.filterTargets.map((t) => `${t.name}=${encodeURIComponent(t.value)}`).join("&")}&reviews_page=1`
    this.makeRequest()
  }

  debouncedFilter(event) {
    const eventCopy = { target: event.target, currentTarget: event.currentTarget };

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.dispatch("search")
      this.filter(eventCopy)
    }, 750)
  }

  makeRequest() {
    this.toggleSpinner()
    Rails.ajax({
      type: "get",
      dataType: "script",
      url: this.urlValue,
      success: (response) => {
        this.reviewsTarget.innerHTML = response.html
        this.toggleSpinner()
      }
    })
  }

  toggleSpinner() {
    document.getElementById("spinner").classList.toggle("hidden")
    this.reviewsTarget.classList.toggle("opacity-20")
  }
}
