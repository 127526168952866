import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    import('swiper').then(({default: Swiper, Navigation, Pagination}) => {
      Swiper.use([Navigation, Pagination]);

      this.swiper = new Swiper(this.element, {
        ...this.defaultOptions,
        ...this.optionsValue
      })
    })
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }

  get defaultOptions() {
    return {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
      breakpoints: {
        768: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        1024: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        1280: {
          slidesPerView: 4,
          slidesPerGroup: 4
        }
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    }
  }
}
