import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "partial", "destroyInput" ];

  removePartial() {
    this.partialTarget.classList.add("hidden")
    this.destroyInputTarget.value = true
  }
}
