import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { siteKey: String, action: String };

  connect() {
    // input is dynamically inserted by the recaptcha_v3 rails helper
    const recaptchaHiddenInput = document.querySelector(`input[type="hidden"][name*="${this.actionValue}"]`)

    if (recaptchaHiddenInput) {
      this.responseInputTarget = recaptchaHiddenInput
    }
  }

  verifyRecaptcha(event) {
    event.preventDefault()

    grecaptcha.ready(() => {
      grecaptcha.execute(this.siteKeyValue, { action: this.actionValue }).then((token) => {
        this.responseInputTarget.value = token
        event.target.submit()
      })
    })
  }
}
