import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.filterAttribute = this.data.get("filterAttribute")
    this.hideParent = (this.data.get("hideParent") == "true") || false
    this.itemsSelector = this.data.get("itemsSelector")
    this.items = document.querySelectorAll(this.itemsSelector)
    this.parentSelectors = (this.data.get("parentSelectors") || '').split(',')
  }

  filterItems(e) {
    this.items.forEach(item => {
      if (item.dataset[this.filterAttribute].indexOf(e.target.value.toLowerCase()) == -1) {
        item.classList.add('hidden')
        if (this.hideParent) { this.maybeHideParents(item) }
      } else {
        item.classList.remove('hidden')
        if (this.hideParent) { this.showParents(item) }
      }
    })
  }

  maybeHideParents(item) {
    let parentElements = []
    this.parentSelectors.forEach(parentSelector => {
      parentElements = parentElements.concat(item.closest(parentSelector))
    })
    parentElements.forEach(parentElement => {
      if (parentElement) {
        let childItems = parentElement.querySelectorAll(this.itemsSelector)
        let hiddenChildItems = parentElement.querySelectorAll(`${this.itemsSelector}.hidden`)
        if (childItems.length == hiddenChildItems.length) {
          parentElement.classList.add('hidden')
        }
      }
    })
  }

  showParents(item) {
    let parentElements = []
    this.parentSelectors.forEach(parentSelector => {
      parentElements = parentElements.concat(item.closest(parentSelector))
    })
    parentElements.forEach(parentElement => {
      if (parentElement) {
        parentElement.classList.remove('hidden')
      }
    })
  }
}
