import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]
  static classes = ["default"]

  toggle(e) {
    if (e.target.tagName.toLowerCase() != 'a' || (e.target.tagName.toLowerCase() === 'a' && !!e.target.dataset['toggleTarget'])) {
      this.contentTargets.forEach((target) =>
        this._classes(target).forEach((className) =>
          target.classList.toggle(className)
        )
      )
    }
  }

  _classes(target) {
    if (target.dataset.toggleClass) {
      return target.dataset.toggleClass.split(" ")
    }

    return this.defaultClass.split(" ")
  }
}
