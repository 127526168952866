import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "form"]
  static values = {
    clearOnSubmit: Boolean
  }

  connect() {
    this.sessionStorageKey = window.location
  }

  restore() {
    if (sessionStorage.getItem(this.sessionStorageKey) != null) {
      const data = Object.fromEntries(JSON.parse(sessionStorage.getItem(this.sessionStorageKey)))

      for (const field of this.fieldTargets) {
        this._setField(field, data[field.id])
      }

      for (const field of this.formTarget) {
        this._setField(field, data[field.name])
      }
    }
  }

  store() {
    const data = []
    const formData = new FormData(this.formTarget);

    for (const pair of formData.entries()) {
      data.push([pair[0], pair[1]])
    }

    for (const pair of this.fieldTargets) {
      data.push([pair.id, this._getFieldValue(pair)])
    }

    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(data))
  }

  clearStorage() {
    if (sessionStorage.key(this.sessionStorageKey) && this.clearOnSubmitValue == true) {
      sessionStorage.clear()
    }
  }

  _setField(field, value) {
    if (field.type == "text")
      field.value = value
    else if (field.type == "select-one")
      field.value = value
    else if (field.type == "radio")
      field.checked = field.value == value
    else if (field.type == "checkbox")
      field.checked = field.value == value
    else
      field.innerHTML = value
  }

  _getFieldValue(field) {
    return "value" in field ? field.value : field.innerHTML
  }
}
