import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { backgroundImageStyle: String }

  lazyLoadBackgroundImage() {
    this.element.style.backgroundImage = this.backgroundImageStyleValue
  }

}
