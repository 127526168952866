import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    import('litepicker').then(litepicker => {
      import('litepicker/dist/plugins/ranges').then(plugins => {
        this.inputTargets.forEach(input => {
          let rangeArray = input.value.split(' - ')
          let startDate = rangeArray[0]
          let endDate = rangeArray[1]
          let singleMode = !!input.dataset.singleMode
          const picker = new Litepicker({
            element: input,
            endDate: endDate,
            // format: 'MM/DD/YYYY',
            numberOfColumns: 2,
            numberOfMonths: 2,
            plugins: this.pluginsFor(singleMode),
            ranges: this.rangesFor(singleMode),
            singleMode: singleMode,
            startDate: startDate
          });
          if (!!startDate && !!endDate) {
            picker.setDateRange(startDate, endDate, true)
          }
        })
      })
    })
  }

  pluginsFor(singleMode) {
    if (!singleMode) {
      return ['ranges']
    }
  }

  rangesFor(singleMode) {
    let d = new Date;
    if (!singleMode) {
      let ranges = {
        customRanges: {
          'First Half of Last Year': [new Date(`${d.getFullYear() - 1}/01/01`), new Date(`${d.getFullYear() - 1}/06/30`)],
          'Last Half of Last Year': [new Date(`${d.getFullYear() - 1}/07/01`), new Date(`${d.getFullYear() - 1}/12/31`)],
          'All of Last Year': [new Date(`${d.getFullYear() - 1}/01/01`), new Date(`${d.getFullYear() - 1}/12/31`)]
        }
      }
      return ranges
    }
  }
}
