import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    hide: Boolean
  }

  render() {
    if (this.hideValue == true || sessionStorage.getItem("shown_get_matched_modal")) { return }

    setTimeout(() => {
      this.element.src = this.urlValue
      sessionStorage.setItem("shown_get_matched_modal", true)
    }, 10000)
  }
}
