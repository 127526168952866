import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { loggedIn: Boolean }

  initialize() {
    document.addEventListener("user:loggedIn", (e) => this.updateNavigation() )
    document.addEventListener("user:loggedIn", (e) => this.updateLoggedInState() )
  }

  updateNavigation() {
    Rails.ajax({
      type: "get",
      url: "/dynamic_content/header",
      dataType: "script"
    })
  }

  updateLoggedInState() {
    Rails.ajax({
      type: "get",
      url: "/dynamic_content/logged_in_state",
      dataType: "script"
    })
  }
}
