import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  successHandler(event) {
    let [data, status, xhr] = event.detail
    let el = document.createElement("template")
    el.innerHTML = xhr.response
    document.querySelector("body").append(el.content)
  }

  failHandler(event) {
    let [data, status, xhr] = event.detail
    let el = document.createElement("template")
    el.innerHTML = xhr.response
    event.target.replaceWith(el.content)
  }

  replaceHandler(event) {
    let [data, status, xhr] = event.detail
    document.querySelector("#modal_container").outerHTML = xhr.response
  }

}
