import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "section", "button" ]
  static values = {
    section: { type: String, default: "courses" }
  }

  initialize() {
    if (this.sectionTargets.find(e => e.dataset.quickLinksSectionName == this._extractAnchor())) {
      this.sectionValue = this._extractAnchor()
    }
  }

  setSection(event) {
    this.sectionValue = event.target.dataset.quickLinksSectionValue
  }

  sectionValueChanged() {
    this.showCurrentSection()
  }

  showCurrentSection() {
    this.sectionTargets.forEach(element => {
      element.classList.toggle("hidden", element.dataset.quickLinksSectionName != this.sectionValue)
    })
    this.buttonTargets.forEach(element => {
      element.classList.toggle("quick-links--selected", element.dataset.quickLinksSectionValue == this.sectionValue)
    })
  }

  scrollSectionToTop() {
    let offset = document.querySelector("#quick_links_scroll_anchor").getBoundingClientRect().top

    if (offset <= 80) {
      document.querySelector("#quick_links_scroll_anchor").scrollIntoView()
    }
  }

  scrollToAnchor(event) {
    if (this._extractAnchor() != '' || event.params.scroll == "force") {
      document.querySelector("#quick_links_scroll_anchor").scrollIntoView({ behavior: "smooth" })
    }
  }

  _extractAnchor() {
    return window.location.hash.slice(1)
  }
}
