import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  updateBackToTopPosition() {
    var pageHeight = document.body.scrollHeight
    var viewportHeight = window.innerHeight

    if (viewportHeight * 5 < pageHeight) {
      var yPos = document.body.getBoundingClientRect().top * -1

      if (yPos > pageHeight * 0.2) {
        this.element.classList.remove('hidden')
      } else {
        this.element.classList.add('hidden')
      }
    }
  }

  scrollToTop(e) {
    e.preventDefault();
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
