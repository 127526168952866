import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    siteKey: String,
    container: { type: String, default: "recaptcha" }
  }

  initialize() {
    if (typeof grecaptcha == "undefined") {
      this.loadScript()
    } else {
      this.render()
    }
  }

  loadScript() {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=explicit&onload=recaptchaCallback"
    script.async = true
    script.defer = true
    document.head.appendChild(script)

    window.recaptchaCallback = function () {
      const recaptchaLoadedEvent = new Event("recaptcha:loaded")
      window.dispatchEvent(recaptchaLoadedEvent)
    }
  }

  render() {
    grecaptcha.render(this.containerValue, { sitekey: this.siteKeyValue })
  }
}
